var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(9, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : [
            _c(
              "v-row",
              { staticClass: "my-2 mx-0" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "form-data",
                      {
                        ref: "chemicallistFormRef",
                        attrs: {
                          references: _vm.formReferences,
                          model: _vm.modelObj,
                        },
                        on: {
                          "update:references": function ($event) {
                            _vm.formReferences = $event
                          },
                        },
                      },
                      [
                        _vm.modelObj.id && _vm.listOfDocuments.length > 0
                          ? _c(
                              "template",
                              { slot: "chemicallist_documents" },
                              [
                                _c("documents", {
                                  attrs: {
                                    documents: _vm.listOfDocuments,
                                    preventFileDelete: _vm.preventFileDelete,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.modelObj.is_checklist_motor === 1
              ? _c(
                  "v-row",
                  { staticClass: "mb-2 mx-0" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-speed-dial",
                          {
                            staticClass: "custom-speed-dial",
                            attrs: { bottom: "", fixed: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            dark: "",
                                            fab: "",
                                          },
                                          model: {
                                            value: _vm.showItems,
                                            callback: function ($$v) {
                                              _vm.showItems = $$v
                                            },
                                            expression: "showItems",
                                          },
                                        },
                                        [
                                          _vm.showItems
                                            ? _c("v-icon", [
                                                _vm._v("mdi-close"),
                                              ])
                                            : _c("v-icon", [
                                                _vm._v("mdi-menu"),
                                              ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3103796628
                            ),
                            model: {
                              value: _vm.showItems,
                              callback: function ($$v) {
                                _vm.showItems = $$v
                              },
                              expression: "showItems",
                            },
                          },
                          _vm._l(_vm.speedDialItems, function (item) {
                            return _c(
                              "v-btn",
                              {
                                key: item.name + "_menuitem",
                                staticClass: "text-capitalize",
                                attrs: {
                                  "min-width": "80px",
                                  color: "primary",
                                  to: item.to,
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }